import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OurMission = () => (
  <Layout linkColor="#5388ff">
    <SEO title="Our mission" linkColor="#5388ff"/>
  <div className="wavy">
    <div className="paddedBody grayBody">
    <h1>Our mission</h1>
    <p>Humans are the only animal species that are present in every nook and cranny of the earth. Even in the middle of the Pacific ocean, there are humans that have been there for thousands of years.</p>
    <p>Maybe they had to leave their settlements because they were driven away by a rival clan, because an earthquake destroyed their villages, maybe it was too hot, too cold, or maybe there wasn’t enough rain. They could have taken their horses and donkeys and moved somewhere more hospitable by land. But they didn’t. They built canoes that they took out on the open seas to travel towards the unknown. Let that settle in. They risked the lives of their families to go sailing on open seas without any knowledge of what they would find… In canoes.</p>
    <p>This isn’t a decision that was driven by logical incentives. This was an absurd choice, driven by absurd desires.</p>
    <p>This absurd and inexhaustible human desire to know what’s beyond the horizon: what will I see? Who’s there? Are there strange animals? Is there air? Or water?</p>
    <p>This absurd desire to know everything, to know more, is there a rational explanation that drives the asking of these questions?</p>
    <p>And yet, it’s this absurd desire that put a man on the moon, that pushed us to invent the concept of zero, that allowed us to discover why apples fall from trees, and this absurd desire that sent people into the middle of the Pacific ocean.</p>
    <br />
    <p>Interested in exploring? Contact us at <a href="mailto:jonarlabs@jonar.com">jonarlabs@jonar.com</a>.</p></div>
  </div>
  </Layout>
)

export default OurMission
